<template>
  <div>
    <div id="map" ref="map"></div>
    <UpdateObjectDialog
      :active="dialogs.updateObject"
      :object="selectedObject"
    />
    <div v-show="false">
      <div ref="infowindow_content">
        <v-card>
          <v-card-title>
            {{ selectedObject.label }}
          </v-card-title>
          <v-card-subtitle>
            {{
              selectedObject.properties.Stationskennzeichen
                ? selectedObject.properties.Stationskennzeichen + " -"
                : ""
            }}
            {{
              selectedObject.properties.Schaltstellenkennzeichen
                ? selectedObject.properties.Schaltstellenkennzeichen + " -"
                : ""
            }}
            {{
              selectedObject.properties.Schlüsselbezeichnung
                ? selectedObject.properties.Schlüsselbezeichnung + " -"
                : ""
            }}
            {{
              selectedObject.anlagenKuerzel
                ? selectedObject.anlagenKuerzel + " -"
                : ""
            }}
            {{
              selectedObject.mainCategory == "In Planung" ||
              selectedObject.mainCategory == "In Umsetzung"
                ? selectedObject.mainCategory + " -"
                : ""
            }}
            {{
              selectedObject.postal_code
                ? selectedObject.postal_code + " -"
                : ""
            }}
            {{ selectedObject.city ? selectedObject.city + " -" : "" }}
            {{ getObjectType(selectedObject) }}
          </v-card-subtitle>

          <template v-if="selectedObject.mainCategory == 'Ladesäule'">
            <v-card-text v-if="selectedObject.properties?.totalACAmount > 0">
              Ladepunkt
              <span
                :class="
                  selectedObject.properties?.availableACAmount > 0
                    ? 'green--text'
                    : 'red--text'
                "
              >
                {{ selectedObject.properties?.availableACAmount }}/{{
                  selectedObject.properties?.totalACAmount
                }}
              </span>
              <span class="font-italic"> (AC) Available/Gesamt </span>
            </v-card-text>
            <v-card-text v-if="selectedObject.properties?.totalDCAmount > 0">
              Schnellladepunkt
              <span
                :class="
                  selectedObject.properties?.availableDCAmount > 0
                    ? 'green--text'
                    : 'red--text'
                "
              >
                {{ selectedObject.properties?.availableDCAmount }}/{{
                  selectedObject.properties?.totalDCAmount
                }}
              </span>
              <span class="font-italic"> (DC) Available/Gesamt </span>
            </v-card-text>
          </template>
          <v-card-text class="pb-0">
            {{
              selectedObject.properties.Zuwegung
                ? "Zuwegung: " + selectedObject.properties.Zuwegung
                : ""
            }}
            {{
              selectedObject.properties.description
                ? selectedObject.properties.description
                : ""
            }}
          </v-card-text>
          <v-card-actions
            v-if="
              selectedObject.mainCategory == 'In Planung' ||
              selectedObject.mainCategory == 'In Umsetzung'
            "
          >
            <v-spacer></v-spacer>
            <v-btn color="primary" icon small @click="openUpdateDialog">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-actions class="py-0">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    :href="getNavigationURL(selectedObject)"
                    target="_blank"
                    small
                  >
                    Navigieren
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn @click="toggleToRoutePlanner(selectedObject)" small>
                    <v-icon> mdi-map-marker-plus </v-icon>
                    Routenplaner
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import PatchedMarkerClusterer from "../../marker-clusterer.js";
//import MarkerClusterer from "@google/markerclustererplus";
import { mapState } from "vuex";

import UpdateObjectDialog from "@/components/app/dialog/updateObject.vue";

export default {
  components: {
    UpdateObjectDialog,
  },

  data() {
    return {
      infowindow: null,
      map: null,
      markerClusterer: null,
      mapOptions: {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: "greedy",
        scrollwheel: false,
        maxZoom: 17,
        minZoom: 2,
        zoom: 13,
        // restriction: {
        //     latLngBounds: {
        //         north: 52.420419,
        //         south: 49.779311222680086,
        //         west: 4.726402,
        //         east: 7.696475412532441,
        //     },
        //     strictBounds: false,
        // },
        center: {
          lat: 51.19425333656996,
          lng: 6.431026354180709,
        },
        styles: [
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.medical",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
      onLine: navigator.onLine,
      selectedObject: {
        geometry: {
          coordinates: [6, 51],
        },
        label: "",
        objectType: "",
        anlagenKuerzel: "",
        properties: {
          Stationskennzeichen: "",
          Schaltstellenkennzeichen: "",
          Zuwegung: "",
          description: "",
        },
      },
      radiusArray: [],
      dialogs: { updateObject: 0 },
    };
  },

  computed: {
    ...mapState("poi", ["restoring", "items", "singleItem"]),
    isReady() {
      return this.map !== null && this.restoring === false;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|MAPP/i.test(
        navigator.userAgent
      );
    },

    combineWatcher() {
      return {
        items: this.$store.state.categories.selectedFilterValue,
        isRadiusChange: this.$store.state.radius.values,
      };
    },
  },

  mounted() {
    // FIXME move this to initial loading screen, App.vue
    // if (!loginNew.serviceAllowed("S1pcEj_jZX"))  {
    //   this.$router.push("/cookies");
    //   // e
    //   return;
    // }

    if (!window.google) {
      this.loadMap();
    } else {
      this.initMap();
    }

    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },

  watch: {
    isReady(val) {
      if (val) {
        // If map is ready and we have items, restore filters.
        this.$store.commit("initSelectedFilterState");
      }
    },
    items(items) {
      this.removeRadius();
      const markers = items.map((obj) => {
        return this.createMarkerObject(obj);
      });
      this.setMarker(markers);
    },

    combineWatcher: {
      handler: function ({ items }) {
        this.$store.dispatch("poi/findItems", items);
      },
      deep: true,
    },

    //"$store.state.categories.selectedFilterValue": {
    //  handler(val) {
    //    this.$store.dispatch("poi/findItems", val);
    //  },
    //},
    singleItem(val) {
      let markers;
      if (val) {
        const foundItem = this.items.some((item) => item.id == val.id);
        if (foundItem) {
          markers = this.items.map((obj) => this.createMarkerObject(obj));
        } else {
          markers = this.items.map((obj) => this.createMarkerObject(obj));
          markers.push(this.createMarkerObject(val));
        }
      } else {
        markers = this.items.map((obj) => this.createMarkerObject(obj));
      }
      this.setMarker(markers);
    },
    onLine(v) {
      if (!v) {
        console.log("is offline");
        this.$store.commit("setListView", true);
        this.$router.push({ path: "/offline", replace: true });
      }
    },
  },
  methods: {
    openUpdateDialog() {
      this.dialogs.updateObject++;
    },

    removeRadius() {
      if (this.radiusArray.length) {
        this.radiusArray.forEach((radius) => radius.setMap(null));
      }
    },

    getNavigationURL({ geometry }) {
      // Waypoint Google Maps App q=destination&waypoints=via coordinate1 | via coordinate 2
      // `google.navigation:q=${geometry.coordinates[1]},${geometry.coordinates[0]}&waypoints=50.943389842247505%2C6.955973131972474%7C51.220543124877146%2C6.791892235259009`
      return this.isMobile
        ? `google.navigation:q=${geometry.coordinates[1]},${geometry.coordinates[0]}`
        : `https://maps.google.com/maps?daddr=${geometry.coordinates[1]},${geometry.coordinates[0]}&amp;ll=`;
    },
    getObjectType({ objectType }) {
      if (objectType.indexOf("-") > 0) {
        return objectType.split(" - ")[1];
      }
      return objectType;
    },
    loadMap() {
      const apiKey = process.env.VUE_APP_GOOGLEMAPS_API_KEY;
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      window.initMap = this.initMap;
    },
    initMap() {
      this.infowindow = new google.maps.InfoWindow({
        content: this.$refs["infowindow_content"],
      });
      this.map = new google.maps.Map(this.$refs.map, this.mapOptions);
    },
    centerMap(markers) {
      console.log("CENTER MAP ", markers.length);
      if (markers.length === 0) {
        return;
      }
      // Calculate the bounds of all markers
      let bounds = new google.maps.LatLngBounds();
      if (this.singleItem) {
        const singleItemMarker = this.createMarkerObject(this.singleItem);
        bounds.extend(singleItemMarker.getPosition());
      } else {
        bounds = new google.maps.LatLngBounds();
        markers.forEach((marker) => {
          bounds.extend(marker.getPosition());
        });
      }
      // Adjust the viewport to fit all markers
      this.map.fitBounds(bounds);
    },
    setMarker(markers) {
      if (this.markerClusterer) {
        this.markerClusterer.clearMarkers();
      }

      this.markerClusterer = new PatchedMarkerClusterer(this.map, markers, {
        // this.markerClusterer = new MarkerClusterer(this.map, markers, {
        minimumClusterSize: 3,
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        gridSize: 70,
        maxZoom: 16,
        // clusterIconSize: new google.maps.Size(90, 90),
      });

      this.centerMap(markers);
    },
    createMarkerObject(obj) {
      const { geometry } = obj;
      const { coordinates } = geometry;
      const correctedCoordinate = this.getCorrectedCoordinate(
        coordinates[1],
        coordinates[0]
      );
      const position = new google.maps.LatLng(
        correctedCoordinate[1],
        correctedCoordinate[0]
      );

      const marker = new google.maps.Marker({
        position,
        optimize: true,
      });

      if (this.isChargingStation(obj)) {
        marker.setLabel({
          className: "charging-station-amount-icon",
          fontWeight: "900",
          fontSize: "12px",
          color: this.getChargingStationIconFontColor(obj),
          text: `${obj.properties?.totalAvailableAmount}/${obj.properties?.totalAmount}`,
        });
      }

      if (obj.icon) {
        if (
          obj.mainCategory == "Ladesäule" ||
          obj.mainCategory == "Schnellladesäule" ||
          obj.mainCategory == "In Planung" ||
          obj.mainCategory == "In Umsetzung"
        ) {
          marker.setIcon({
            scaledSize: new google.maps.Size(60, 60),
            url: obj.icon,
          });
        } else {
          marker.setIcon({
            scaledSize: new google.maps.Size(35, 35),
            url: obj.icon,
          });
        }
      }

      if (obj.mainCategory == "In Planung") {
        this.radiusArray.push(
          new google.maps.Circle({
            fillColor: "green",
            fillOpacity: 0.1,
            strokeOpacity: 0,
            map: this.map,
            center: {
              lat: parseFloat(coordinates[1]),
              lng: parseFloat(coordinates[0]),
            },
            radius: parseInt(this.$store.state.radius.values.inPlanung),
          })
        );
      }

      if (obj.mainCategory == "In Umsetzung") {
        this.radiusArray.push(
          new google.maps.Circle({
            fillColor: "yellow",
            fillOpacity: 0.1,
            strokeOpacity: 0,
            map: this.map,
            center: {
              lat: parseFloat(coordinates[1]),
              lng: parseFloat(coordinates[0]),
            },
            radius: parseInt(this.$store.state.radius.values.inUmsetzung),
          })
        );
      }

      if (obj.objectType == "In Betrieb - Ladesäule") {
        this.radiusArray.push(
          new google.maps.Circle({
            fillColor: "red",
            fillOpacity: 0.1,
            strokeOpacity: 0,
            map: this.map,
            center: {
              lat: parseFloat(coordinates[1]),
              lng: parseFloat(coordinates[0]),
            },
            radius: parseInt(this.$store.state.radius.values.inBetrieb),
          })
        );
      }

      if (obj.objectType == "In Betrieb - Schnellladesäule") {
        this.radiusArray.push(
          new google.maps.Circle({
            fillColor: "blue",
            fillOpacity: 0.1,
            strokeOpacity: 0,
            map: this.map,
            center: {
              lat: parseFloat(coordinates[1]),
              lng: parseFloat(coordinates[0]),
            },
            radius: parseInt(this.$store.state.radius.values.inBetrieb),
          })
        );
      }

      if (obj.objectType == "") {
        if (obj.properties?.radius) {
          this.radius = new google.maps.Circle({
            fillColor: "blue",
            fillOpacity: 0.1,
            strokeOpacity: 0,
            map: this.map,
            center: {
              lat: parseFloat(coordinates[1]),
              lng: parseFloat(coordinates[0]),
            },
            radius: parseInt(obj.properties.radius),
          });
        }
      }

      marker.addListener("click", () => {
        this.selectedObject = obj;
        this.infowindow.open(this.map, marker);
      });
      return marker;
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
    //coordinates(obj) {
    //  return {
    //    lat: obj.geometry.coordinates[1],
    //    lng: obj.geometry.coordinates[0],
    //  };
    //},
    toggleToRoutePlanner(obj) {
      if (!this.existsInRoutePlanner(obj)) {
        this.$store.dispatch("poi/addRoute", obj);
      }
    },
    existsInRoutePlanner(obj) {
      return this.$store.getters["poi/existsInRoutePlanner"](obj);
    },
    getCorrectedCoordinate(lat, lon) {
      if (lat > 45 && lat < 55) {
        return [lon, lat];
      } else {
        return [lat, lon];
      }
    },
    isChargingStation(obj) {
      return ["AC", "DC", "MIXED"].includes(obj.properties?.type);
    },

    getChargingStationIconFontColor(obj) {
      if (obj.mainCategory == "In Planung") {
        return "#11bc00";
      }
      if (obj.mainCategory == "In Umsetzung") {
        return "#fbed00";
      }
      if (["AC", "MIXED"].includes(obj.properties?.type)) {
        return "#f32a00";
      }
      return "#056eb1";
    },

    goToManagePage() {
      this.$router.push("/manage").catch((e) => {
        console.error(e.message);
      });
    },
  },

  beforeDestroy() {
    delete window.google;
    const scripts = document.getElementsByTagName("script");
    for (let i = scripts.length - 1; i >= 0; i--) {
      const script = scripts[i];
      if (script.src.includes("maps.googleapis.com")) {
        script.parentNode.removeChild(script);
      }
    }

    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>

<style>
#map {
  height: 100vh;
  width: 100%;
}
.charging-station-amount-icon {
  position: relative;
  bottom: 7px;
}
</style>
